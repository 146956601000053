// iconfont
@font-face {
	font-family: 'iconfont';
	src: url('../iconfont/iconfont.eot');
	src: url('../iconfont/iconfont.eot?#iefix') format('embedded-opentype'),
	url('../iconfont/iconfont.woff') format('woff'),
	url('../iconfont/iconfont.ttf') format('truetype'),
	url('../iconfont/iconfont.svg#iconfont') format('svg');
}
.iconfont{
	font-family:"iconfont" !important;
	font-size: 16px;
	font-style: normal;
	-webkit-font-smoothing: antialiased;
	-webkit-text-stroke-width: 0.2px;
	-moz-osx-font-smoothing: grayscale;
}
html{
	min-width: 1300px;
	width: 100%;
	overflow-x: hidden;
	height: 100%;
}
body{
	position: relative;
	width: 100%;
	min-height:100%;
	overflow-x: hidden;
}
.l_con{
	position: relative;
	margin: 0 auto;
	width: 1200px;
}
img[class^="icon_"]{
	display: inline-block;
	width: 100%;
	cursor: pointer;
	vertical-align: middle;
	.select(none);
}
i[class^="icon_"]{
	vertical-align: middle;
	background-size: 100% 100%;
	background-repeat: no-repeat;
	display: inline-block;
	font-style: normal;
}
body{
	font-size: @fs-base;
}
.wrapper{
	.pr;
	max-width: 1920px;
	margin: 0 auto;
}
/**清除浮动，以及第一个孩子的margin-top:,以及最后一个孩子margin-bottom:错乱的问题上下边*/
.clear{
	.clearfix();
}
.box{
	.box();
}
.fl{
	float: left;
}
.fr{
	float: right;
}
.toh{
	.toh;
}
.f-theme{
	color: @themeColor;
}
.noWrap{
	white-space: nowrap;
}
.fl{
	float: left;
}
.fr{
	float: right;
}
.tc{
	text-align: center;
}
.tl{
	text-align: left;
}
.tr{
	text-align: right;
}
.pa{
	position: absolute;
}
.pr{
	position: relative;
}
.pf{
	position: fixed;
}
.wf{
	.width();
}
.hide{
	display: none;
}
.toh{
	.toh();
}
.blod{
	font-weight: bold;
}
/**grid*/
*[class^="g-"]{
	.clearfix;
}
.g-d-2{
	.grid-de(2);
}
.g-d-3{
	.grid-de(3);
}
.g-d-4{
	.grid-de(4);
}
.g-d-5{
	.grid-de(5);
}
.g-d-6{
	.grid-de(6);
}
.g-d-7{
	.grid-de(7);
}
.g-d-8{
	.grid-de(8);
}
.bg-c{
	background-color: @themeColor;
}
.bg-sc{
	background-color: @subColor;
}
.f-c{
	color: @themeColor;
}
.f-sc{
	color: @subColor;
}
.f-t-c{
	text-align: center;
}
.f-wb{
	font-weight: bold;
}
.btHov{
	.transition(.22s);
	&:hover{
		.opacity(.85);
	}
}
.noShow{
	visibility: hidden;
}
.b_subNav(@tg:@themeColor){}
.btn-auto-width(@btn-height,@btn-bgcolor,@btn-color,@btn-radius,@padding){
	.inline-block();
	height: @btn-height;
	line-height: @btn-height;
	background: @btn-bgcolor;
	color: @btn-color;
	border-radius: @btn-radius;
	padding: 0 @padding;
}