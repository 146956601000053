.page_new_list{
	.pr;
	.sec_2{
		.l_con{
			.clear;
			padding-bottom: 50px;
		}
	}
	.left{
		.pr;
		.fl;
		width:262px;
	}
	.right{
		position: relative;
		.fr;
		width: 860px;
		margin-top: 15px;
		border-top: 1px solid #cbcbcb;
		.rslt{
			position: relative;
			.comp_loading{
				position: absolute;
			}
		}
		.no_data{
			margin-top: 77px;
			text-align: center;
			margin-bottom: 77px;
			i{
				width: 156px;
				height: 158px;
				display: inline-block;
				background-repeat: no-repeat;
				background-image: url('../images/change_doc/sub/no_data.png');
			}
			.txt{
				margin-top: 20px;
				text-align: center;
				.f(14px,#888,100%);
				letter-spacing: 1px;
			}
		}
	}
}