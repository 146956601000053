.site_addr{
	padding-top:30px;
	.clear;
	.icon_home{
		.pr;
		float: left;
		top: 2px;
		width: 18px;
		height: 17px;
		margin-right: 5px;
		margin-left: -8px;
		background: url('../images/change_doc/fun/home_1.png') no-repeat;
	}
	.tit{
		position: relative;
		float: left;
		left: 0px;
		width: 260px;
		height: 100px;
		cursor: pointer;
		margin-top: -80px;
		.f(@fs7,#fff,100px);
		font-weight: bold;
		.transition(.22s);
		text-align: center;
		background-repeat: no-repeat;
		background-color: @themeColor;
		&:hover{
			background-color: darken(@themeColor,10);
		}
	}
	.addr{
		position: relative;
		float: right;
		width: 860px;
		height:20px;
		>div{
			.toh;
		}
		&,a{
			.f(@fs2,@g3,20px);
		}
		a{
			margin:0px 8px;
			display:inline-block;
		}
		.icon_line{
			font-weight:bold;
		}
		.active{
			color: @themeColor;
		}
	}
}