/**统一的二级导航*/
.b_subNav(@tg:@themeColor){

}

/**统一的二级导航*/
.b_subNav{
	width: 260px;
	margin-bottom: 55px;
	background-color: #f5f5f5;
	.nav_a{
		position: relative;
		font-size: 0px;
		.width;
		>li{
			height: 61px;
			margin-left: 4px;
			margin-right: 20px;
			border-bottom: 1px solid #dfe2e5;
			.nav_tit{
				height: 63px;
				.transition(.1s);
				margin-top: -1px;
				margin-left: -4px;
				margin-right: -20px;
				background-color: transparent;
				border-left: 3px solid transparent;
				>div{
					width: 100%;
					height: 100%;
					a{
						width: 100%;
						height: 100%;
						display: block;
						.transition(.1s);
						.f(@fs4,#333,63px);
						text-align: center;
					}
				}
			}
			&:last-child{
				border-bottom: 1px solid transparent;
			}
			&:hover,&.active{
				.nav_tit{
					background-color: #dce1e8;
					border-left: 3px solid @themeColor;
					>div{
						a{
							color: @themeColor;
						}
					}
				}
			}
		}
	}
}