.page_new_detail{
	@import "../base/banner.less";
	.news_wrap{
		margin-top: 30px;
		padding-bottom: 50px;
		position: relative;
		>img{
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
		}
		.news{
			.tit{
				text-align: center;
				margin-bottom: 10px;
				.f(@fs7,@themeColor,50px);
			}
			.info{
				.f(13px,#999,100%);
				margin: 0 0 10px;
				text-align: center;
				>span{
					margin: 0px 10px;
				}
			}
			.font-set{
				.f(13px,#999,100%);
				text-align: right;
				a{
					cursor: pointer;
				}
			}
			.line{
				.width;
				height: 1px;
				margin-top: 35px;
				margin-bottom: 20px;
				background-color: #ccc;
			}
			.detail{
				position: relative;
				text-align: justify;
				text-justify: inter-ideograph;
				.f(@fs3,#333,175%);
				ul, li, ol, dl, dd, dt, p, h1, h2, h3, h4, h5, h6, form, fieldset, legend, img, div{
					text-align: justify;
					text-justify: inter-ideograph;
				}
				&,p,div{
					text-align: justify;
					text-justify: inter-ideograph;
				}
				img,table{
					max-width: 100%!important;
				}
				p{
					margin: 1.5em 0;
				}
			}
		}
	}
	.turn_page{
		margin-top: 22px;
		a{
			font-size: 16px;
			color: #333;
			margin-bottom: 6px;
			&:hover{
				color: @themeColor;
			}
		}
	}
}

.page_new_detail_1{
	@import "../base/banner.less";
	.sec_2{
		.l_con{
			.clear;
			padding-bottom: 50px;
		}
	}
	.left{
		.pr;
		.fl;
		width:262px;
	}
	.right{
		position: relative;
		.fr;
		.clear;
		width: 860px;
		margin-top: 15px;
		border-top: 1px solid #cbcbcb;
	}
	.news_wrap{
		margin-top: 30px;
		padding-bottom: 50px;
		position: relative;
		>img{
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
		}
		.news{
			.tit{
				text-align: center;
				margin-bottom: 10px;
				.f(@fs7,@themeColor,50px);
			}
			.info{
				.f(13px,#999,100%);
				margin: 0 0 10px;
				text-align: center;
				>span{
					margin: 0px 10px;
				}
			}
			.font-set{
				.f(13px,#999,100%);
				text-align: right;
				a{
					cursor: pointer;
				}
			}
			.line{
				.width;
				height: 1px;
				margin-top: 35px;
				margin-bottom: 20px;
				background-color: #ccc;
			}
			.detail{
				position: relative;
				text-align: justify;
				text-justify: inter-ideograph;
				.f(@fs3,#333,175%);
				ul, li, ol, dl, dd, dt, p, h1, h2, h3, h4, h5, h6, form, fieldset, legend, img, div{
					text-align: justify;
					text-justify: inter-ideograph;
				}
				&,p,div{
					text-align: justify;
					text-justify: inter-ideograph;
				}
				img,table{
					max-width: 100%!important;
				}
				p{
					margin: 1.5em 0;
				}
			}
		}
	}
	.turn_page{
		margin-top: 22px;
		a{
			font-size: 16px;
			color: #333;
			margin-bottom: 6px;
			&:hover{
				color: @themeColor;
			}
		}
	}
}