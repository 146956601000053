.page_video_list{
	@import "../base/banner.less";
	padding-bottom: 50px;
	.sec_2{
		margin-top: 62px;
		.l_con{
			.clear;
		}
	}
	.no_data{
		margin-top: 77px;
		text-align: center;
		margin-bottom: 77px;
		i{
			width: 156px;
			height: 158px;
			display: inline-block;
			background-repeat: no-repeat;
			background-image: url('../images/change_doc/sub/no_data.png');
		}
		.txt{
			margin-top: 20px;
			text-align: center;
			.f(14px,#888,100%);
			letter-spacing: 1px;
		}
	}
	.video_list{
		.clear;
		margin-left: -21px;
		margin-right: -21px;
		>li{
			.pr;
			.fl;
			width: 372px;
			height: 288px;
			margin: 0px 21px 45px;
			font-size: 0px;
			overflow: hidden;
			background-color: #efefef;
			.img{
				.pr;
				width: 100%;
				height: 288px;
				display: block;
				.transition(.15s);
				background-size: cover;
				background-position: center;
				.icon_play{
					.pa;
					top: 111px;
					left: 50%;
					width: 80px;
					height: 80px;
					margin-top: -40px;
					margin-left: -40px;
					background-position: center;
					background-repeat: no-repeat;
					background-image: url('../images/change_doc/sub/icon_play_1.png');
				}
			}
			&:hover{
				.img{
					.icon_play{
						background-image: url('../images/change_doc/sub/icon_play_1_hover.png');
					}
				}
			}
			.cover{
				position: absolute;
				height: 66px;
				bottom: 0px;
				.width;
				text-align: center;
				.rgba(0,0,0,.5);
				.tit{
					.toh;
					height: 66px;
					display: block;
					.transition(.22s);
					margin-left: 40px;
					margin-right: 40px;
					.f(@fs4,#fff,66px);
					text-align: center;
					&:hover{
						color: #f1f1f1;
					}
				}
			}
			&:hover{
				>img{
					transform: scale(1.2);
				}
			}
		}
	}
	.no_data{
		margin-top: 77px;
		text-align: center;
		margin-bottom: 77px;
		i{
			width: 156px;
			height: 158px;
			display: inline-block;
			background-repeat: no-repeat;
			background-image: url('../images/change_doc/sub/no_data.png');
		}
		.txt{
			margin-top: 20px;
			text-align: center;
			.f(14px,#888,100%);
			letter-spacing: 1px;
		}
	}
	.paging{
		padding-top: 20px;
	}
	.paging_header{

	}
}

.page_video_list_1{
	@import "../base/banner.less";
	padding-bottom: 50px;
	.no_data{
		margin-top: 77px;
		text-align: center;
		margin-bottom: 77px;
		i{
			width: 156px;
			height: 158px;
			display: inline-block;
			background-repeat: no-repeat;
			background-image: url('../images/change_doc/sub/no_data.png');
		}
		.txt{
			margin-top: 20px;
			text-align: center;
			.f(14px,#888,100%);
			letter-spacing: 1px;
		}
	}
	.sec_2{
		.l_con{
			.clear;
			padding-bottom: 50px;
		}
	}
	.left{
		.pr;
		.fl;
		width:262px;
	}
	.right{
		position: relative;
		.fr;
		.clear;
		width: 860px;
		margin-top: 15px;
		border-top: 1px solid #cbcbcb;
	}
	.video_list{
		.clear;
		margin-right: -26px;
		margin-top: 50px;
		>li{
			.pr;
			.fl;
			width: 270px;
			height: 200px;
			margin-right: 25px;
			margin-bottom: 35px;
			font-size: 0px;
			overflow: hidden;
			background-color: #efefef;
			.img{
				.pr;
				width: 100%;
				height: 200px;
				display: block;
				.transition(.15s);
				background-size: cover;
				background-position: center;
				.icon_play{
					.pa;
					top: 79px;
					left: 50%;
					width: 62px;
					height: 62px;
					margin-top: -31px;
					margin-left: -31px;
					background-image: url('../images/change_doc/sub/icon_play_1.png');
				}
			}

			&:hover{
				.img{
					.icon_play{
						background-image: url('../images/change_doc/sub/icon_play_1_hover.png');
					}
				}
			}
			.cover{
				position: absolute;
				height: 45px;
				bottom: 0px;
				.width;
				text-align: center;
				.rgba(0,0,0,.5);
				.tit{
					.toh;
					height: 45px;
					display: block;
					.transition(.22s);
					margin-left: 22px;
					margin-right: 22px;
					.f(@fs4,#fff,45px);
					text-align: center;
					&:hover{
						color: #f1f1f1;
					}
				}
			}
			&:hover{
				>img{
					transform: scale(1.2);
				}
			}
		}
	}
	.paging{
		padding-top: 20px;
	}
}