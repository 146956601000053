.header{
	.pr;
	.width;
	top: 0px;
	.floor(3);
	left: 0px;
	height: 140px;
	background-image: url('../images/change_doc/hb/bg_header.png');
	background-repeat: no-repeat;
	background-position: center top;
	>.l_con{
		.pr;
		height: 120px;
		width: 1250px;
	}
	.logo{
		width: 421px;
		height: 61px;
		display: block;
		background: url('../images/change_doc/hb/logo.png') no-repeat;
		background: url('../images/change_doc/hb/logo.svg') center/contain no-repeat;
		background-size: 100%;
		background-position: center;
	}
	.left{
		.fl;
		width: 393px;
		.logo{
			margin-top: 32px;
		}
	}
	.right{
		.pa;
		top: 0px;
		right: 0px;
		bottom: 0px;
		height: 100%;
		width: 600px;
	}
	.search{
		.pr;
		height: 32px;
		margin-top: 32px;
		margin-right: 22px;
		input{
			.fr;
			color: #fff;  
			width: 256px;
			height: 30px;
			font-size: 14px;
			line-height: 30px;
			padding-left: 20px;
			padding-right: 44px;
			.border-radius(32px);
			border: 1px solid #8995a6;
			background-color: transparent;
			&{
				.placeholder({
					color: #8995a6;
					font-size: 14px;
				});
			}
			&.placeholder{
				color: #8995a6;
				font-size: 14px;
			}
		}
		.btn_search{
			.pa;
			top: 8px;
			right: 20px;
			width: 16px;
			height: 16px;
			background-image: url('../images/change_doc/hb/search.png');
		}
	}
	.menus{
		.pa;
		right: 0px;
		bottom: 0px;
		width: 850px;
		>ul{
			.fr;
			height: 35px;
			>li{
				.pr;
				.fl;
				>a{
					.pr;
					height: 35px;
					display: block;
					.transition(.15s);
					padding-left: 22px;
					padding-right: 22px;
					.f(15px,#fff,35px);
					border-radius: 2px;
				}
				>.subs{
					.pa;
					.clear;
					left: 50%;
					top: 35px;
					opacity: 0;
					min-width: 100%;
					visibility: hidden;
					*visibility: visible;
					*display: none;
					transform: translate(-50%,0);
					-ms-transform: translate(-50%,0);
					-webkit-transform: translate(-50%,0);
					-o-transform: translate(-50%,0);
					-moz-transform: translate(-50%,0);
					.transition(.22s);
					>ul{
						margin-top: 15px;
						border-radius: 2px;
						background-color: #fff;
						.box-shadow(#efefef,0px,0px,5px);
					}
					>ul > li{
						background-color: #fff;
						>a{
							.tc;
							.noWrap;
							.transition(.1s);
							height: 35px;
							display: block;
							padding-left: 23px;
							padding-right: 23px;
							.f(14px,#333,35px);
						}
						&:hover{
							background-color: @themeColor;
							a{
								color: #fff;
							}
						}
					}
				}
				&.active{
					background-color: #fff;
					>a{
						color: @themeColor;
					}
				}
				&:hover{
					>a{
						background-color: #f6bf45;
					}
					>.subs{
						visibility: visible;
						opacity: .9;
						*display: block;
						// height: auto;
					}
				}
			}
		}
	}
	&.header_fixed{
		.pf;
		.width;
		top: 0px;
		left: 0px;
		height: 45px;
		background-color: #000;
		background-color: rgba(0,0,0,.8);
		animation: move ease .8s 1;
		border-top: 5px solid @themeColor;
		-webkit-animation: move ease .8s 1;
		>.l_con{
			height: 45px;
		}
		.left {
			.logo{
				margin-top: 0px;
				width: 323px;
				height: 40px;
				display: block;
				background: url('../images/cms/index/logo_2.png') no-repeat;
				background: url('../images/cms/index/logo_2.svg') center/contain no-repeat;
				background-size: auto 100%;
				background-position: left center;
			}
		}
		.search{
			display: none;
		}
		.menus{
			>ul{
				>li{
					>.subs{
						>li{
							background-color: @themeColor;
							>a{
								color: #fff;
							}
							&:hover{
								background-color: #fff;
								a{
									color: @themeColor;
								}
							}
						}
					}
					&.active{
						background-color: #fff;
						>a{
							color: @themeColor;
						}
					}
					&:hover{
						>a{
							color: #fff;
							background-color: @themeColor;
						}
						>.subs{
							visibility: visible;
							opacity: 1;
							*display: block;
							// height: auto;
						}
					}
				}
			}
		}
	}
}

.footer{
	background-color: #d1d6e3;
	.l_con{
		.clear;
		padding-top: 27px;
		padding-bottom: 15px;
		.logo{
			.fl;
			width: 256px;
			height: 60px;
			margin-top: 8px;
			background: url('../images/change_doc/hb/logo_footer.png') no-repeat;
			/*background: url('../images/change_doc/hb/logo_footer.svg') center/contain no-repeat;*/
			background-size: auto 100%;
		}
		.center{
			.fl;
			width: 700px;
			margin-top: 15px;
			margin-left: 22px;
			padding-left: 22px;
			.links{
				.clear;
				margin-top: -10px;
				.item{
					.fl;
					margin-right: 22px;
					padding-top: 5px;
					padding-bottom: 5px;
					.f(14px,@themeColor,22px);
					&:hover{
						text-decoration: underline;
					}
				}
			}
			.info{
				margin-bottom: -10px;
				padding-top: 5px;
				.f(12px,#8892b6,22px);
				padding-bottom: 5px;
			}
		}
		.codes{
			.fr;
			.code_1,.code_2{
				.fl;
				width: 60px;
				.img{
					width: 60px;
					height: 60px;
					background-image: url('../images/change_doc/hb/code_wb.png');
					background-repeat: no-repeat;
					background-color: #efefef;
				}
				.txt{
					.pr;
					.tc;
					left: 50%;
					width: 150px;
					margin-top: 8px;
					margin-left: -75px;
					.f(14px,@themeColor,100%);
				}
			}
			.code_2{
				margin-left: 35px;
				.img{
					background-image: url('../images/change_doc/hb/code_gf.png');
				}
			}
		}
		
	}
	.support{
		.tc;
		height: 45px;
		.f(12px,#bbb,45px);
		background-color: @themeColor;
	}
}