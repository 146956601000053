.page_video_detail{
	@import "../base/banner.less";
	.sec_2{
		padding-bottom: 50px;
		margin-top: 62px;
		.l_con{
			.clear;
		}
	}
	.video_detail{
		width: 1200px;
		margin-top: 30px;
		padding-bottom: 50px;
		.tit{
			text-align: center;
			margin-bottom: 10px;
			.f(@fs7,@themeColor,50px);
		}
		.info{
			.f(13px,#999,100%);
			margin: 0 0 10px;
			text-align: center;
			>span{
				margin: 0px 10px;
			}
		}
		.comp_video{
			background-color: #efefef;
			margin-top: 30px;
			height: 675px;
			width: 100%;
		}
		.content{
			.pr;
			margin-top: 25px;
			text-align: justify;
			text-justify: inter-ideograph;
			.f(@fs3,#333,175%);
			ul, li, ol, dl, dd, dt, p, h1, h2, h3, h4, h5, h6, form, fieldset, legend, img, div{
				text-align: justify;
				text-justify: inter-ideograph;
			}
			&,p,div{
				text-align: justify;
				text-justify: inter-ideograph;
			}
			img,table{
				max-width: 100%!important;
			}
			p{
				margin: 1.5em 0;
			}
		}
	}
}
.page_video_detail_1{
	@import "../base/banner.less";
	.sec_2{
		.l_con{
			.clear;
			padding-bottom: 50px;
		}
	}
	.left{
		.pr;
		.fl;
		width:262px;
	}
	.right{
		position: relative;
		.fr;
		.clear;
		width: 860px;
		margin-top: 15px;
		border-top: 1px solid #cbcbcb;
	}
	.video_detail{
		.width;
		margin-top: 30px;
		padding-bottom: 50px;
		.tit{
			text-align: center;
			margin-bottom: 10px;
			.f(@fs7,@themeColor,50px);
		}
		.info{
			.f(13px,#999,100%);
			margin: 0 0 10px;
			text-align: center;
			>span{
				margin: 0px 10px;
			}
		}
		.comp_video{
			background-color: #efefef;
			width: 100%;
			height: 479px;
			margin-top: 30px;
		}
	}
}