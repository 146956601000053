.page_img_detail{
	@import "../base/banner.less";
	.sec_2{
		margin-top: 62px;
		.l_con{
			.clear;
		}
	}
	.show_img{
		.tc;
		margin-top: 55px;
		>img{
			.width;
		}
	}
	.img_detail{
		margin-top: 30px;
		padding-bottom: 50px;
		position: relative;
	}
	.detail{
		position: relative;
		text-align: justify;
		text-justify: inter-ideograph;
		.f(@fs3,#333,175%);
		ul, li, ol, dl, dd, dt, p, h1, h2, h3, h4, h5, h6, form, fieldset, legend, img, div{
			text-align: justify;
			text-justify: inter-ideograph;
		}
		&,p,div{
			text-align: justify;
			text-justify: inter-ideograph;
		}
		img,table{
			max-width: 100%!important;
		}
		p{
			margin: 1.5em 0;
		}
	}
	.img_tit{
		text-align: center;
		margin-bottom: 10px;
		.f(@fs7,@themeColor,50px);
	}
	.info{
		.f(13px,#999,100%);
		margin: 0 0 10px;
		text-align: center;
		>span{
			margin: 0px 10px;
		}
	}
	.line{
		.width;
		height: 1px;
		margin-top: 20px;
		margin-bottom: 20px;
		background-color: #ccc;
	}
	.turn_page{
		margin-top: 22px;
		a{
			font-size: 16px;
			color: #333;
			margin-bottom: 6px;
			&:hover{
				color: @themeColor;
			}
		}
	}
}