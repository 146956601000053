.page_img_list{
	@import "../base/banner.less";
	padding-bottom: 50px;
	.no_data{
		margin-top: 77px;
		text-align: center;
		margin-bottom: 77px;
		i{
			width: 156px;
			height: 158px;
			display: inline-block;
			background-repeat: no-repeat;
			background-image: url('../images/change_doc/sub/no_data.png');
		}
		.txt{
			margin-top: 20px;
			text-align: center;
			.f(14px,#888,100%);
			letter-spacing: 1px;
		}
	}
	.sec_2{
		.l_con{
			.clear;
			padding-bottom: 50px;
		}
	}
	.left{
		.pr;
		.fl;
		width:262px;
	}
	.right{
		position: relative;
		.fr;
		.clear;
		width: 860px;
		margin-top: 15px;
		border-top: 1px solid #cbcbcb;
	}
	.newsList{
		.clear;
		margin-right: -26px;
		margin-top: 50px;
		>li{
			.pr;
			.fl;
			width: 270px;
			height: 200px;
			margin-right: 25px;
			margin-bottom: 35px;
			font-size: 0px;
			overflow: hidden;
			background-color: #efefef;
			.img{
				.pr;
				width: 100%;
				height: 200px;
				display: block;
				.transition(.15s);
				background-size: cover;
				background-position: center;
			}
			&:hover{
				.img{
					transform: scale(1.1);
				}
			}
			.cover{
				position: absolute;
				height: 45px;
				bottom: 0px;
				.width;
				text-align: center;
				.rgba(0,0,0,.5);
				.tit{
					.toh;
					height: 45px;
					display: block;
					.transition(.22s);
					margin-left: 22px;
					margin-right: 22px;
					.f(@fs3,#fff,45px);
					text-align: center;
					&:hover{
						color: #f1f1f1;
					}
				}
			}
			&:hover{
				>img{
					transform: scale(1.2);
				}
			}
		}
	}
	.paging{
		padding-top: 20px;
	}
}