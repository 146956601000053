.pagination {
	height: 50px;
	display: table;
	overflow: hidden;
	margin: 30px auto 0;
	font-size: 0px;
	li {
		float: left;
		margin: 8px;
		color: #666;
		height: 30px;
		.transition(.05s);
		border-radius: 2px;
		border: 1px solid #c7c7c7;
		&:hover {
			color: #fff;
			background: @themeColor;
			border: 1px solid @themeColor;
			a{
				color: #fff;
			}
		}
		a {
			width: 30px;
			height: 30px;
			display: block;
			color: #666;
			font-size: 14px;
			line-height: 30px;
			border-radius: 5px;
			text-align: center;
			text-decoration: none;
		}
	}
	.active {
		background: @themeColor;
		border: 1px solid @themeColor;
		a {
			color: #fff;
		}
	}
}