.news_list{
	>li{
		.clear;
		padding-top: 22px;
		padding-bottom: 22px;
		border-bottom: 1px dashed #cbcbcb;
		.tit{
			.fl;
			.toh;
			width: 735px;
			.transition(.05s);
			&,a{
				.f(@fs3,#333,100%);
			}
		}
		.date{
			.fr;
			.transition(.05s);
			.f(@fs3,#666,100%);
		}
		&:hover{
			.tit{
				&,a{
					color: @themeColor;
				}
			}
			.date{
				color: @themeColor;
			}
		}
	}
}