.page_index{
	@color: #30343f;
	@subCo: #f6bf45;
	.pr;
	.width;
	background-color: #fff;
	max-width: 1920px;
	.banner{
		.pr;
		left: 50%;
		width: 1920px;
		height: 420px;
		margin-left: -960px;
		.bannerImg{
			font-size: 0px;
			display: block;
		}
		.swiper-slide{
			font-size: 0px;
		}
		.pagination{
			position: absolute;
			.floor(2);
			left: 50%;
			bottom: 0px;
			height: 62px;
			width: 1920px;
			// .rgba(0,0,0,.5);
			text-align: center;
			margin-left: -960px;
			background-color: transparent;
		}
		.swiper-pagination-switch{
			width: 9px;
			height: 9px;
			.inline-block;
			margin: 0px 14px;
			margin-top: 25px;
			.transition(.22s);
			background-color: #fff;
			box-shadow: 0px 0px 5.6px 0.4px rgba(0, 0, 0, 0.3);
		}
		.swiper-active-switch{
			width: 38px;
			.border-radius(11px);
			background-color: #fff;
		}
	}

	.fun_menu{
		.pr;
		height: 150px;
		background-color: #f6f7fb;
		box-shadow: 0px 3px 7px 0px rgba(17, 51, 130, 0.2);
		.container{
			width: 1200px;
			height: 150px;
			margin: 0 auto;
		}
		ul{
			.clear;
			>li{
				.fl;
				.tc;
				width: 171px;
				height: 150px;
				.transition(.15s);
				.img{
					height: 40px;
					margin-top: 39px;
				}
				.txt{
					.tc;
					margin-top: 15px;
					.f(17px,@color,100%);
				}
				&.l1{
					.img{
						background: url('../images/cms/index/icon_1.png') center no-repeat;
					}
					.imgPreLoadByBefore('../images/cms/index/icon_1_hover.png');
				}
				&.l2{
					.img{
						background: url('../images/cms/index/icon_2.png') center no-repeat;
					}
					.imgPreLoadByBefore('../images/cms/index/icon_2_hover.png');
				}
				&.l3{
					.img{
						background: url('../images/cms/index/icon_3.png') center no-repeat;
					}
					.imgPreLoadByBefore('../images/cms/index/icon_3_hover.png');
				}
				&.l4{
					.img{
						background: url('../images/cms/index/icon_4.png') center no-repeat;
					}
					.imgPreLoadByBefore('../images/cms/index/icon_4_hover.png');
				}
				&.l5{
					.img{
						background: url('../images/cms/index/icon_5.png') center no-repeat;
					}
					.imgPreLoadByBefore('../images/cms/index/icon_5_hover.png');
				}
				&.l6{
					.img{
						background: url('../images/cms/index/icon_6.png') center no-repeat;
					}
					.imgPreLoadByBefore('../images/cms/index/icon_6_hover.png');
				}
				&.l7{
					.img{
						background: url('../images/cms/index/icon_7.png') center no-repeat;
					}
					.imgPreLoadByBefore('../images/cms/index/icon_7_hover.png');
				}
				&:hover{
					.txt{
						color: @themeColor;
					}
					&.l1{
						.img{
							background: url('../images/cms/index/icon_1_hover.png') center no-repeat;
						}
					}
					&.l2{
						.img{
							background: url('../images/cms/index/icon_2_hover.png') center no-repeat;
						}
					}
					&.l3{
						.img{
							background: url('../images/cms/index/icon_3_hover.png') center no-repeat;
						}
					}
					&.l4{
						.img{
							background: url('../images/cms/index/icon_4_hover.png') center no-repeat;
						}
					}
					&.l5{
						.img{
							background: url('../images/cms/index/icon_5_hover.png') center no-repeat;
						}
					}
					&.l6{
						.img{
							background: url('../images/cms/index/icon_6_hover.png') center no-repeat;
						}
					}
					&.l7{
						.img{
							background: url('../images/cms/index/icon_7_hover.png') center no-repeat;
						}
					}
				}
			}
		}
	}

	.m_tit{
		.pr;
		height: 25px;
		margin-top: 72px;
		.en{
			.pa;
			width: 100%;
			top: -22px;
			left: 0px;
			.f(12px,#9b9caf,100%);
		}
		.ca{
			.pr;
			font-size: 0px;
			.inline-block;
			height: 25px;
			.txt{
				.pr;
				z-index: 2;
				font-weight: bold;
				.f(@fs7,@themeColor,25px);
			}
			.line{
				.pa;
				left: 0px;
				right: 0px;
				bottom: 0px;
				height: 6px;
				display: block;
				background-color: @subCo;
			}
		}
		.see_more{
			.pa;
			right: 22px;
			padding-right: 19px;
			.f(13px,@themeColor,25px);
			background: url('../images/cms/index/icon_arrow_right.png') right center no-repeat;
			&:hover{
				color: @subCo;
				background: url('../images/cms/index/icon_arrow_right_hover.png') right center no-repeat;
			}
		}
	}

	.sec_3{
		>.container{
			.clear;
			width: 1200px;
			margin: 0 auto;
		}
	}

	.sec_4{
		.container{
			.clear;
			width: 1200px;
			margin: 0 auto;
			padding-bottom: 52px;
		}
		.m_tit{
			.tc;
			.en{
				color: #8898c0;
			}
		}
		.m_com{
			height: 180px;
			margin-top: 30px;
			background-color: #daefff;
			box-shadow: 0px 3px 7px 0px rgba(17, 51, 130, 0.2);
			background: url('../images/cms/index/bg_sec_4.png') center no-repeat;
			.container{
				height: 180px;
			}
		}
		ul{
			.clear;
			>li{
				.fl;
				.tc;
				width: 1200px/5;
				height: 180px;
				.transition(.15s);
				.img{
					height: 56px;
					margin-top: 45px;
				}
				.txt{
					.tc;
					margin-top: 10px;
					.f(17px,@themeColor,125%);
				}
				&.l1{
					.img{
						background: url('../images/cms/index/icon_sc1.png') center no-repeat;
					}
				}
				&.l2{
					.img{
						background: url('../images/cms/index/icon_sc2.png') center no-repeat;
					}
				}
				&.l3{
					.img{
						background: url('../images/cms/index/icon_sc3.png') center no-repeat;
					}
				}
				&.l4{
					.img{
						background: url('../images/cms/index/icon_sc4.png') center no-repeat;
					}
				}
				&.l5{
					.img{
						background: url('../images/cms/index/icon_sc5.png') center no-repeat;
					}
				}
				&:hover{
					background-color: #f6f7fb;
				}
			}
		}
	}

	.module_1,.module_2{
		.fl;
		width: 715px;
		.m_con{
			.clear;
			padding-top: 40px;
		}
		.new_strong{
			.pr;
			.fl;
			width: 120px*2;
			height: 288px;
			.tim{
				.pa;
				top: 0px;
				left: 0px;
				width: 54px;
				height: 49px;
				font-size: 0px;
				background-color: @subCo;
				.day{
					.tc;
					display: block;
					.f(@fs7,#fff,100%);
					letter-spacing: -1px;
					margin-top: 6px;
				}
				.moth{
					.tc;
					display: block;
					.f(12px,#fff,100%);
				}
			}
			.img{
				height: 160px;
				overflow: hidden;
				>div{
					.width;
					height: 100%;
					.transition(.1s);
					background-size: cover;
					background-color: #efefef;
					background-position: center;
					background-repeat: no-repeat;
				}
				&:hover{
					>div{
						transform: scale(1.1);
					}
				}
			}
			.tit{
				.toh(3);
				height: 96px;
				padding-top: 15px;
				padding-left: 20px;
				padding-right: 20px;
				padding-bottom: 15px;
				.f(@fs3,@color,25px);
				border-bottom: 2px solid #cfd6e6;
				&:hover{
					color: @themeColor;
				}
			}
		}
		.list{
			.fr;
			width: 454px;
			margin-top: -17px;
			>li{
				.clear;
				height: 60px;
				border-bottom: 1px solid #e1e4f1;
				.tim{
					.fl;
					width: 47.5px;
					height: 56px;
					font-size: 0px;
					.day{
						.tc;
						display: block;
						.f(@fs7,@subCo,100%);
						letter-spacing: -1px;
						margin-top: 12px;
					}
					.moth{
						.tc;
						.noWrap;
						display: block;
						margin-top: 2px;
						letter-spacing: -.5px;
						.f(10px,@subCo,100%);
					}
				}
				.tit{
					.fr;
					.toh;
					width: 392px;
					height: 56px;
					&,a{
						.f(@fs2,@color,60px);
					}
				}
				&:hover{
					.tim{
						.day{
							color: @themeColor;
						}
						.moth{
							color: @themeColor;
						}
					}
					.tit{
						&,a{
							color: @themeColor;
						}
					}
				}
			}
		}
	}

	.module_2{
		.fr;
		width: 454px;
	}

	.sec_5{
		>.container{
			.clear;
			width: 1200px;
			margin: 0 auto;
			padding-bottom: 52px;
		}
	}

	.module_3,.module_4{
		.fl;
		width: 715px;
		.m_con{
			.clear;
			margin-top: 40px;
		}
		.new_strong{
			.fl;
			.pr;
			width: 240px;
			height: 236px;
			overflow: hidden;
			.img{
				.width;
				height: 100%;
				.transition(.1s);
				background-size: cover;
				background-color: #efefef;
				background-position: center;
				background-repeat: no-repeat;
			}
			.tit{
				.pa;
				.toh;
				left: 0px;
				right: 0px;
				bottom: 0px;
				.rgba(0,0,0,.5);
				padding-left: 15px;
				padding-right: 15px;
				padding-top: 13px;
				padding-bottom: 15px;
				.f(@fs3,#fff,125%);
			}
			&:hover{
				.img{
					transform: scale(1.1);
				}
			}
		}
		.list{
			.fr;
			width: 454px;
			margin-top: -19px;
			>li{
				height: 50px;
				border-bottom: 1px solid #e7eaf2;
				.tim{
					.fl;
					width: 110px;
					font-weight: bold;
					.f(@fs3,@color,50px);
				}
				.tit{
					.fr;
					.toh;
					width: 340px;
					&,a{
						.f(@fs3,@color,50px);
					}
				}
				&:hover{
					.tim{
						color: @themeColor;
					}
					.tit{
						&,a{
							color: @themeColor;
						}
					}
				}
			}
		}
	}
	.module_4{
		.fr;
		width: 454px;
	}

	.sec_6{
		height: 120px*4;
		background: url('../images/cms/index/bg_sec_6.jpg') center no-repeat;
		>.container{
			.clear;
			width: 1200px;
			margin: 0 auto;
		}
		.m_tit{
			.tc;
			.en{
				color: #9b9caf;
			}
			.ca{
				.txt{
					color: #fff;
				}
			}
		}
		.m_con{
			.pr;
			margin-top: 50px;
			margin-left: -10px;
			margin-right: -10px;
			.swiper-container{
				height: 210px;
				.item{
					.pr;
					margin-left: 10px;
					margin-right: 10px;
					display: block;
					height: 210px;
					.img{
						.pr;
						.width;
						height: 100%;
						background-size: cover;
						background-color: #efefef;
						background-repeat: no-repeat;
						background-position: center;
						.icon_play{
							.pa;
							width: 50px;
							height: 50px;
							left: 50%;
							top: 50%;
							margin-top: -25px;
							margin-left: -25px;
							background: url('../images/cms/index/icon_play.png') center no-repeat;
						}
					}
					.tit{
						.pa;
						left: 0px;
						right: 0px;
						bottom: 0px;
						.transition(.1s);
						visibility: hidden;
						opacity: 0;
						background-color: rgba(255,255,255,.8);
						background-color: #efefef;
						padding: 8px 20px;
						.f(@fs2,@themeColor,22px);
					}
					&:hover{
						.img{
							.icon_play{
								background: url('../images/cms/index/icon_play_hover.png') center no-repeat;
							}
						}
						.tit{
							visibility: visible;
							opacity: 1;
						}
					}
				}
			}
			.swiper-button-prev{
				.pa;
				top: 82px;
				left: -40px;
				width: 30px;
				height: 45px;
				background: url('../images/cms/index/arrow_left.png') center no-repeat;
				&:hover{
					background: url('../images/cms/index/arrow_left_hover.png') center no-repeat;
				}
			}
			.swiper-button-next{
				.pa;
				top: 82px;
				right: -40px;
				width: 30px;
				height: 45px;
				background: url('../images/cms/index/arrow_right.png') center no-repeat;
				&:hover{
					background: url('../images/cms/index/arrow_right_hover.png') center no-repeat;
				}
			}
			.btns{
				.tc;
				font-size: 0px;
				margin-top: 40px;
				.btn_see_more{
					.inline-block;
					width: 120px;
					height: 30px;
					background: url('../images/cms/index/btn_more.png') center no-repeat;
					&:hover{
						background: url('../images/cms/index/btn_more_hover.png') center no-repeat;
					}
				}
			}
		}
	}
}