.banner{
	position:relative;
	>img{
		position:relative;
		display:block;
		left:50%;
		margin-left:-960px;
	}
	.cover{
		position:absolute;
		top:0px;
		left:0px;
		height:100%;
		width:100%;
		.txt{
			.center(c3);
			.width;
			.ca{
				.width;
				.f(27px,#fff);
				font-weight: bold;
				text-align:center;
				margin-top:25px;
			}
			.en{
				.width;
				.f(40px,#fff);
				font-weight:bold;
				text-align:center;
			}
		}
	}
}